<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2"><u>Substrate specificity of trypsin and α-chymotrypsin</u></p>
      <p class="mb-3">Enter your experimental data in the table below:</p>

      <table class="mb-3">
        <thead>
          <tr>
            <th>Enzyme and Substrate Used</th>
            <th>Initial Rate (abs/min)</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td style="font-weight: bold">Chymotrypsin + S</td>
            <td>
              <calculation-input
                v-model="inputs.chymotrypsinS"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">Trypsin + S</td>
            <td>
              <calculation-input
                v-model="inputs.trypsinS"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">Trypsin + BA</td>
            <td>
              <calculation-input
                v-model="inputs.trypsinBA"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td style="font-weight: bold">Chymotrypsin + BA</td>
            <td>
              <calculation-input
                v-model="inputs.chymotrypsinBA"
                class="centered-input my-1 mx-n1"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput.vue';

export default {
  name: 'BiochemUManEx4DataPartC',
  components: {
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chymotrypsinS: null,
        trypsinS: null,
        trypsinBA: null,
        chymotrypsinBA: null,
      },
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
